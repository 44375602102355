import { createGlobalStyle } from "styled-components"
import { theme } from "./theme"

const GlobalStyle = createGlobalStyle`
  html,
  body {
    width: 100%;
    height: 100%;
    position: relative;
    padding: 0px;
    margin: 0px;
    font-family: Titillium Web, sans-serif;
    color: ${theme.colors.text};
  }

h1 {
  font-size: min(calc(20px + 3vw), 56px);
  font-weight: 600;
  line-height: min(calc(22px + 3vw), 60px);
  padding: 0;
  margin: 0;
  text-align: left;
  white-space: pre-wrap;
}

h2 {
  font-size: min(calc(14px + 3vw), 42px);
  font-weight: 600;
  line-height: min(calc(15px + 3vw), 46px);
  padding: 0;
  margin: 0;
  text-align: left;
  white-space: pre-wrap;
}

  p{
    font-size: min(calc(14px + 0.3vw), 18px);
    font-weight: 400;
    line-height: 21px;
    padding: 0;
    margin: 0;
    text-align: left;
    white-space: pre-wrap;
  }

  button {
    cursor: pointer;
    border: none;
    border-radius: 10px;
    background: ${theme.colors.primary};
    color: ${theme.colors.white};
    text-align: center;
    white-space: pre-wrap;
    font-size: min(calc(12px + 0.3vw), 16px);
    font-weight: 600;
    line-spacing: 0px;
    padding: 0 10px;
  }


`

export default GlobalStyle
