const config: {
  email: string
  linkBrit: string
  downloadGooglePlay: string
  downloadAppStore: string
  appInBrowser: string
  videoLink: string
} = {
  email: "app@petlifebook.com",
  linkBrit: "https://krmivo-brit.cz/cs/profil-spolecnosti",
  downloadGooglePlay:
    "https://play.google.com/store/apps/details?id=com.petlifebook",
  downloadAppStore: "https://apps.apple.com/us/app/pet-lifebook/id1620323546",
  appInBrowser: "https://app.petlifebook.com",
  videoLink: "https://www.youtube.com/embed/b1gNf_YyQZ4",
}

export default config
