import "../fonts/fonts.css"

import * as React from "react"

import styled, { ThemeProvider } from "styled-components"

import AppFooter from "./AppFooter"
import AppHeader from "./AppHeader"
import GlobalStyles from "../styles/globalStyles"
import Helmet from "react-helmet"
import SVGLogo from "../images/logo-big.svg"
import { theme } from "../styles/theme"
import { useTranslation } from "react-i18next"

const Body = styled.div`
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
`

const Wrapper = styled.div`
  width: 90%;
  max-width: 1100px;
  margin: auto;
`

const Content = styled.div`
  margin-top: min(50px, 5vh);
  display: flex;
  flex-direction: column;
  align-items: stretch;
`

const Blocker = styled.div`
  overflow: hidden;
  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4vh;

  svg {
    width: 90%;
    max-width: 260px;
  }
`

const Text = styled.div`
  max-width: 90%;
  font-size: 20px;
  text-align: center;
`

const AppLayout = (props: { children: any }) => {
  const { t } = useTranslation()

  const useBlocker = process.env.GATSBY_USE_BLOCKER === "true"

  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        {/* CMP (Consent Management Platform) */}
        <script
          id="usercentrics-cmp"
          src="https://app.usercentrics.eu/browser-ui/latest/loader.js"
          data-settings-id="fNi60SXnz"
          async
        ></script>
        <title>{t("TITLE")}</title>
      </Helmet>

      <GlobalStyles />

      <Body>
        {useBlocker ? (
          <Blocker>
            <SVGLogo />
            <Text>{t("BLOCKER")}</Text>
          </Blocker>
        ) : (
          <Wrapper>
            <AppHeader />
            <Content>
              {props.children}
              <AppFooter />
            </Content>
          </Wrapper>
        )}
      </Body>
    </ThemeProvider>
  )
}

export default AppLayout
