import * as React from "react"

import { Link, useI18next } from "gatsby-plugin-react-i18next"
import styled, { css } from "styled-components"

import SVGLogo from "../images/logo.svg"
import config from "../../config"
import { useTranslation } from "react-i18next"

const Wrapper = styled.div`
  width: 100%;
  padding-top: 3vh;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${props => props.theme.breakpoints.S} {
    padding-top: 5vh;
    flex-direction: column;
    gap: 20px;
  }
`

const Logo = styled.div`
  width: 175px;
  & > a {
    text-decoration: none;
  }
`

const Menu = styled.div`
  display: flex;
  align-items: center;
  gap: min(30px, 2vw);

  ${props => props.theme.breakpoints.S} {
    flex-direction: column;
    gap: 0px;
  }
`

const MenuItem = styled.div`
  padding: 5px 10px;
  font-size: 18px;
  cursor: pointer;
  transition: ${props => props.theme.transitions.hover};

  &:hover {
    color: ${props => props.theme.colors.primary};
  }

  a {
    color: inherit;
    text-decoration: none;
  }
`

const LanguageList = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  margin: 10px 3%;
  display: flex;
  align-items: center;
`

const LanguageItem = styled.div<{ isActive?: boolean }>`
  a {
    color: inherit;
    text-decoration: none;
    font-size: 14px;
    padding: 4px;

    ${props =>
      props.isActive
        ? css`
            font-weight: 600;
          `
        : css`
            opacity: 0.3;
          `};
  }
`

const LanguageSeparator = styled.div<{ isActive?: boolean }>`
  opacity: 0.5;
`

const AppHeader = () => {
  const { t } = useTranslation()
  const { languages, originalPath, language } = useI18next()

  return (
    <Wrapper>
      <Logo>
        <Link to="/">
          <SVGLogo />
        </Link>
      </Logo>

      <Menu>
        <MenuItem
          onClick={() =>
            document.getElementById("ID_SECTION_ABOUT")?.scrollIntoView({
              behavior: "smooth",
              block: "start",
              inline: "nearest",
            })
          }
        >
          {t("menu.ABOUT")}
        </MenuItem>

        <MenuItem
          onClick={() =>
            document.getElementById("ID_SECTION_DOWNLOAD")?.scrollIntoView({
              behavior: "smooth",
              block: "center",
              inline: "nearest",
            })
          }
        >
          {t("menu.DOWNLOAD")}
        </MenuItem>

        <MenuItem>
          <a href={`mailto: ${config.email}`}>{t("menu.CONTACT")}</a>
        </MenuItem>
      </Menu>

      <LanguageList>
        {languages.map((lng, i) => (
          <React.Fragment key={lng}>
            <LanguageItem key={lng} isActive={lng === language}>
              <Link to={originalPath} language={lng}>
                {lng}
              </Link>
            </LanguageItem>
            {i < languages.length - 1 && (
              <LanguageSeparator>{"/"}</LanguageSeparator>
            )}
          </React.Fragment>
        ))}
      </LanguageList>
    </Wrapper>
  )
}

export default AppHeader
