import breakpoints from "./breakpoints"

const colors = {
  black: "#525051",
  white: "#ffffff",
  green: "#7CB227",
}

export const theme = {
  colors: {
    ...colors,
    primary: colors.green,
    background: colors.white,
    backgroundDark: colors.black,
    text: colors.black,
  },
  transitions: {
    hover: "all 0.2s ease",
  },
  breakpoints,
}
export type Theme = typeof theme
