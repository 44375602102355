//
// DEFS
//
export type BreakpointEnumType = "XS" | "S" | "M" | "L" | "XL" | "XXL"

export const breakpoints: {
  XXL: string
  XL: string
  L: string
  M: string
  S: string
  XS: string
  XXS: string
} = {
  XXL: "2400px",
  XL: "1600px",
  L: "1200px",
  M: "900px",
  S: "700px",
  XS: "500px",
  XXS: "325px",
}

//
// BREAKPOINT OBJECT
//
const breakpointGen: any = {}
Object.entries(breakpoints).forEach(entry => {
  const size = entry[0]
  const value = entry[1]
  breakpointGen[size] = `@media screen and (max-width: ${value})`
})

const breakpoint: typeof breakpointGen = breakpointGen

export default breakpoint
