import * as React from "react"

import { Link } from "gatsby"
import SVGLogo from "../images/logoInverted.svg"
import SVGLogoBrit from "../images/logoBrit.svg"
import config from "../../config"
import styled from "styled-components"
import { useTranslation } from "react-i18next"

const Wrapper = styled.div`
  min-height: 90px;
  width: 100vw;
  align-self: center;
  background: ${props => props.theme.colors.backgroundDark};
  color: ${props => props.theme.colors.white};
  display: flex;
  align-items: center;

  ${props => props.theme.breakpoints.S} {
    padding: 20px 0;
  }
`

const Content = styled.div`
  position: relative;
  width: 90%;
  max-width: 1000px;
  margin: auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
`

const Logos = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  pointer-events: none;

  ${props => props.theme.breakpoints.S} {
    margin: auto;
    width: 70%;
    position: relative;
  }
  ${props => props.theme.breakpoints.XS} {
    flex-direction: column;
    gap: 20px;
  }
`

const Logo = styled.div`
  pointer-events: auto;
  cursor: pointer;
`

const Menu = styled.div`
  margin: auto;
  width: 100%;
  max-width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: min(200px, 10vw);

  ${props => props.theme.breakpoints.S} {
    max-width: 70%;
  }
  ${props => props.theme.breakpoints.XS} {
    max-width: 100%;
  }
`

const MenuItem = styled.div`
  padding: 10px;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
  transition: ${props => props.theme.transitions.hover};
  padding: 5px;

  &:hover {
    color: ${props => props.theme.colors.primary};
  }

  a {
    color: inherit;
    text-decoration: none;
  }
`

const AppFooter = () => {
  const { t, i18n } = useTranslation()

  const renderLogo = () => {
    return (
      <Logo onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}>
        <SVGLogo />
      </Logo>
    )
  }

  const renderBritLogo = () => {
    return (
      <Logo>
        <a href={config.linkBrit} target="_blank">
          <SVGLogoBrit />
        </a>
      </Logo>
    )
  }

  const renderMenu = () => {
    return (
      <Menu>
        <MenuItem>
          <a href={`mailto: ${config.email}`}>{config.email}</a>
        </MenuItem>

        <MenuItem>
          <Link
            to={
              i18n.language === "cs" ? "/ochrana-soukromi" : "/privacy-policy"
            }
            target="_blank"
          >
            {t("menu.GDPR")}
          </Link>
        </MenuItem>
      </Menu>
    )
  }

  return (
    <Wrapper>
      <Content>
        {renderMenu()}
        <Logos>
          {renderLogo()}
          {renderBritLogo()}
        </Logos>
      </Content>
    </Wrapper>
  )
}

export default AppFooter
